<template>
  <section class="card-container" id="categoryCard">
    <div
      tabindex="-1"
      :id="isContinueWatch ? `continue_watch_${content.objectid}` : `normal_${content.objectid}`"
      class="card"
      :class="highlight ? borderColor : ''"
      @click="cardClickHandler(content)"
      :style="[{ height: `${cardType.height}` }, { width: `${cardType.width}` }, { margin: `${cardType.margin}` }]"
    >
      <imageItem class="carousel-image-item" :source="gotPosterItem" :title="cardTitle" :cardType="cardType.type"/>
      <div
        class="play-img"
        id="play-imgs"
        v-if="cardType.playButton.enablePlay || (screen ? screen.sectionType === 'DIRECTPLAY' : '') || content.category == 'SHORTS'"
      >
        <img
          id="play-img-item"
          src="@/assets/icons/Ic_Play1.svg"
          :width="cardType.playButton.enablePlay ? `${cardType.playButton.width}` : `${cardWidth}`"
          :height="cardType.playButton.enablePlay ? `${cardType.playButton.height}` : `${cardHeight}`"
        />
      </div>

      <div
        class="remove-btn"
        :class="[localDisplayLang === 'ara' ? 'remove-btn-ara' : 'remove-btn-eng']"
        v-if="showRemove"
      >
        <img class="remove-watchlist" src="@/assets/icons/remove-watchlist.svg" @click.stop="removeFromWatchlist(content)" alt />
      </div>

      <div
        class="content-details"
        v-if="showTitle || (screen ? screen.sectionType === 'DIRECTPLAY' : '')"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      >
        <div v-if="content.category === 'MOVIE' && content.title && isContinueWatch"></div>
        <div v-if="content.category === 'TVSHOW' && content.seriesname"></div>
      </div>

      <div
        :class="[localDisplayLang === 'ara' ? 'trailer_tags_container_right' : 'trailer_tags_container']"
        v-if="content.contenttype === 'Trailer'"
      >
        <div class="trailer_tags_container_item" dir="ltr">
          <p>{{ calcTimeFormat(content.duration) }}</p>
        </div>
      </div>

      <div class="tags-container" v-if="displayTags">
        <div class="tags-container-item">
          <!--content tag container-->
          <div
            :class="[localDisplayLang === 'ara' ? 'content-tags-right' : 'content-tags']"
            v-if="isObjectTag(content) && pickTagForContent(content.objecttag[0])"
          >
            <div>
            <img src="@/assets/icons/Premium_1_scg.svg" alt="crown">
            </div>
          </div>
          <div
            :class="[localDisplayLang === 'ara' ? 'category-tags-right' : 'category-tags']"
            v-if="content.objecttype === 'SERIES' && content.seasoncount > 1"
          >
            <div>
              <img src="@/assets/icons/ic_episodes.svg" alt />
              <span v-if="content.objecttype === 'SERIES'">{{ content.seasoncount }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="percentProgress != undefined" class="gray-line" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"></div>
      <div
        v-if="percentProgress"
        class="progress-percent"
        :style="{ width: percentProgress + '%' }"
        :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
      ></div>
    </div>

    <div class="triangle" :class="triangleColor" v-if="highlight && !percentProgress"></div>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";  
import Redirection from "@/mixins/redirection.js";
import { _projectName } from "@/provider-config.js";
import { mapGetters, mapActions } from "vuex";
import { store } from "@/store/store";

export default {
  props: {
    content: {
      type: Object,
    },
    cardType: {
      type: Object,
    },
    screen: {
      type: Object,
    },
    percentProgress: {
      type: Number,
    },
    absoluteProgress: {
      type: Number,
    },
    id: {
      type: [String, Number],
    },
    showRemove: {
      type: Boolean,
    },
    showTitle: {
      type: Boolean,
    },
    hiddenContent: {
      type: Object,
    },
    displayTags: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
    isDirectPlay: {
      type: Boolean,
    },
    isContinueWatch: {
      type: Boolean,
    },
  },
  data() {
    return {
      cardHeight: "40px",
      cardWidth: "40px",
      borderColor: null,
      highlight: false,
      cardWidth: "100%",
      gotPosterItem: null,
      triangleColor: null,
      isContentPlayable: false,
      availabilities: [],
      subscriptions: [],
      filteredAvailabilities: [],
      pricemodel: [],
      localDisplayLang: null,
      completeEpisodeListing: [],
      playlistIndex: -1,
      isMpegRequired: false,
      episodeList: [],
      totalEpisodeCount: 0,
      playbackInitiated: false,
      isActiveStatus: true,
      currentContinueContent: '',
      parentalControlsEnabled: false,
      placeholder_port: require('@/assets/placeholder-port.svg'),
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      cardTitle: 'cardImage'
    };
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
        // this.getAvailability(this.content);
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        // this.getAvailability(this.content);
      }
    },
    content () {
      this.getPoster();
    }
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "availabilityList", "subscriptionList"]),
  },
  created() {
    
   // this.isMpegRequired = this.checkMpegRequired();

    this.getPoster();

    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    // card border color
    this.borderColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "border-color"
        : _projectName === "NAMMAFLIX"
        ? "border-color-white"
        : "";

    // card triangle Color
    this.triangleColor =
      _projectName === "NET5" || _projectName === "VLIVE"
        ? "triangle-color"
        : _projectName === "NAMMAFLIX"
        ? "triangle-color-white"
        : "";
    eventBus.$on("re-render-carousel", () => {
      this.getPoster();
    });

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    eventBus.$on("off", () => {
      this.highlight = false;
    });

    eventBus.$on(`highlight-${this.id}`, () => {
      this.highlight = true;
    });

    eventBus.$on("player-visibility", (isVisible) => {
      if (isVisible == false && this.playbackInitiated == true) {
        this.playbackInitiated = false;

        this.playerInstance.deregisterEvent("currentcontentended");
        this.playerInstance.deregisterEvent("loadchosencontent");
        this.playerInstance.deregisterEvent("loadmorecontent");
      }
    });

    this.getCardTitle();
  },
  methods: {
    ...mapActions([
       "subscriberPlaybackExpiry"
    ]),
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(tag) {
      let contenttags = this.appConfig.contentTags;
      if (this.localDisplayLang === "ara") {
        return contenttags.ar[`${tag}`];
      } else {
        return contenttags.en[`${tag}`];
      }
    },
    cardClickHandler(content) {
      // call content detail api 

      if (this.isContinueWatch) {
          let payload = {
            contentid: content.objectid,
            params: {
              displaylanguage: this.localDisplayLang,
            },
          };
          let expiryPayload = {
            subscriberid: this.subscriberid,
            objectid: content.objectid
          }
          this.subscriberPlaybackExpiry(expiryPayload);
          store.dispatch("contentDetail", payload).then((response) => {
          if (!response.data.reason) {
              this.parentalControlsEnabled = localStorage.getItem("parentalControlEnabled") == 'true' ? true : false;
              if (!this.isContentPlayableParentalLock(this.getCurrentProfile(), response.data.pgrating) && this.isActiveStatus && this.parentalControlsEnabled) {
                eventBus.$emit('event-parental-lock-CB', `continue_watch_${content.objectid}`);
                this.currentContinueContent = content;
                this.isActiveStatus = false
                return;
              } else {
                this.actPlaybackContinueWatch(content);
              }
          }
        })
      } else if(content.castncrewid) {
        let currentLanguage = this.getCurrentLanguageUrlBase();
        let castName = content.castncrewname.replaceAll(" ", '-')
        this.$router.push({
          name: "artist",
          params: { castncrewId: content.castncrewid, castncrewName: castName.toLowerCase(), lang: currentLanguage },
        });
      } else if(this.screen && this.screen.itemType && this.screen.itemType === "GENRES") {
        
        let type = "genre";
        let objType = (this.screenName == "Home" || this.screenName == "TV Shows") ? "SERIES" : this.screenName == 'Channels' ? "CHANEL" : this.screenName; 
        
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: "screen-meta-redirection", params: { objecttype: objType.toLowerCase(), value: content.title , type: type, lang: currentLanguage } });;
      } else {
        if(content.playbacktype && content.playbacktype == "LOCATORBASEDPLAYBACK"){
          this.locatorBasedPlayback(this.content);
          } else {
            this.$router.push({name: 'detailPageEarth', params:{title: content.defaulttitle.replace(' ', '-') ,  contentId: content.objectid}})
          }
          // this.actPlaybackContinueWatch(content);
      }
    },
    actPlaybackContinueWatch(content) {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      if(content && content.playbacktype == "EXTERNALURLBASEDPLAYBACK") {
        let episodeIndex = 0;
        let episodeList = [content];
        let totalCount = episodeList.length;
        let isPlaybackAllowed = this.checkAvailabilityAllowsPlayback(episodeList[episodeIndex]);        
        if (isPlaybackAllowed) {
          this.showPlayer();
          this.actBuyDRMToPlayer(episodeIndex, episodeList, totalCount, true);
        } else if(!isPlaybackAllowed && this.subscriberid) {
          this.redirectToPlans();
        }
        return
      }
      if ((content.objecttype == "CONTENT" || content.objecttype == 'CHANEL') && this.isDirectPlay) {
        if (this.$router.currentRoute.params && this.$router.currentRoute.params.section) {
          localStorage.setItem("playback_source", this.$router.currentRoute.params.section);
        } else {
          localStorage.setItem("playback_source", "HOME");
        }
        this.playContent(content);
      } else {
        if (screen.width > 0) {
            if(content.category == "TVSHOW"){
             let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPageEarth", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title,lang: currentLanguage   } });

          } else {
           let title = this.spacialCharEncoding(content.defaulttitle || content.title);
            this.$router.push({ name: "detailPageEarth", params: { title   , contentId: content.objectid.toLowerCase() ,lang: currentLanguage  } });
          }
        }
      }
    },
    openDetailPopup(content) {
      //This statement to prevent click for trailers.
      if (content.contenttype && content.contenttype === "Trailer") return;

      if (
        content.objecttype !== "CONTENT" ||
        content.category === "MOVIE" ||
        (content.category === "TVSHOW" && content.objecttype !== "CONTENT")
      ) {
        let payload = {
          content: content,
          state: true,
        };
        eventBus.$emit("showContentDetail", payload);
        return;
      }
    },
    getPoster() {
      if (this.content.poster && this.content.poster.length) {
        let index = this.content.poster.findIndex((element) => {
          if (this.cardType.type === "LANDSCAPE") {
            return element.postertype === "LANDSCAPE";
          } else if (this.cardType.type === "PORTRAIT") {
            return element.postertype === "PORTRAIT";
          } else if (this.cardType.type === "SQUARE") {
            return element.postertype === "SQUARE";
          }
        });
        if (index > -1) {
          this.content.poster[index].filelist.filter((el) => {
            //adding thumbnail poster.
            if (this.cardType.quality === "THUMBNAIL" && el.quality === "THUMBNAIL") {
              this.gotPosterItem = el.filename;
            } else {
              if (el.quality === "LOW") {
                // console.log("SD quality", el.filename);
                this.gotPosterItem = el.filename;
              } else if (el.quality === "SD") {
                this.gotPosterItem = el.filename;
              }
            }
          });
        } else {
          this.gotPosterItem = this.content.poster[0].filelist && this.content.poster[0].filelist[0] ? this.content.poster[0].filelist[0].filename : ''
        }
      } else {
        if (this.content.poster && this.content.poster.landscape) {
          this.gotPosterItem = this.content.poster.landscape;

        } else {
          this.gotPosterItem = this.placeholder_lands;
        }
        
      }
    },
   showPlayer() {
      // this.playerInstance.blowUpPlayer();

      this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData);
      this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData);

      this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    playContent(content) {
      

      if (this.isDirectPlay && this.subscriberid) {
        this.showPlayer();

        if (content.category == "MOVIE") {
          let episodeIndex = 0;
          this.episodeList = [content];
          this.totalEpisodeCount = 1;

          this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
        } else {
          let returnCode = this.fetchEpisodeRecursively(this.fetchEpisodes, content, this.checkEpisodeInList, false);

          returnCode
            .then(() => {
              let episodeIndex = this.getEpisodeIndexInList(content.objectid, this.episodeList);

              this.loadChosenIndexContent(episodeIndex, this.episodeList, this.totalEpisodeCount, true);
            })
            .catch((error) => {
              
            });
        }
      } else if (this.subscriberid && !this.isDirectPlay) {
        
        this.$emit("play", content);
      } else if (!this.subscriberid) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    removeFromWatchlist(content) {
      this.$emit("remove", content);
    },
    getCardTitle() {
      this.cardTitle = this.content.title ? (this.content.title.default ? this.content.title.default : this.content.title) : 'cardImage';
    }
  },
  components: {
    imageItem: () => import(/* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"),
  },
  mixins: [Utility, PlaybackUtilities, Redirection],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./categoryCard.scss"
</style>
